import * as React from 'react'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import '@wordpress/block-library/build-style/style.css'

import { Layout } from '../../components/Layout'
import { SEO } from '../../types'

import * as styles from './EmployeeReturns.module.scss'

type Props = {
  data: {
    wpPage: {
      link: string
      id: string
      title: string
      content: string
      status: string
      featuredImage: IGatsbyImageData | null
    }
    seo: SEO
  }
}

const EmployeeReturns = ({ data }: Props) => (
  <Layout>
    <Seo post={data.wpPage} />
    <div
      className={`${styles.EmployeeReturns} w-full`}
    >
      <span dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
    </div>
  </Layout>
)

export default EmployeeReturns

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      link
      id
      title
      content
      status
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
